import { alpha, Box, CircularProgress, Typography, IconButton, Stack, Grid } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FilePreviews } from "./FilePreviews";

type FileUploadComponentProps = {
  handleNewFiles: (files: File[]) => void;
  loading: boolean;
  uploadedFiles?: File[];
  onDeleteFile?: (file: File) => void;
  accept?: { [key: string]: string[] };
  maxSize?: number; // size of file in bytes
  maxFiles?: number; // number of files to upload
};

function FileUploadComponent({
  handleNewFiles,
  loading,
  uploadedFiles,
  onDeleteFile,
  accept = {},
  maxSize = 10 * 1024 * 1024,
  maxFiles = 1,
}: FileUploadComponentProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {};
      reader.readAsArrayBuffer(file);
    });
    handleNewFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept,
    maxSize,
    maxFiles,
  });

  // make file rejection items a unique set
  const uniqueErrors = [
    ...new Map(fileRejections.flatMap((rejection) => rejection.errors).map((error) => [error.code, error.message])).values(),
  ];

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Stack sx={{ cursor: "pointer", backgroundColor: "transparent" }}>
      {/* file uploader */}
      {!uploadedFiles?.length && (
        <Box {...getRootProps(style)}>
          <input {...getInputProps()} />

          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} sx={{ backgroundColor: "transparent" }}>
            <IconButton sx={{ backgroundColor: alpha("#EBEFF7", 0.5), borderRadius: "50%", p: 2 }}>
              <FileUploadOutlinedIcon sx={{ color: "#061e30" }} />
            </IconButton>
            <Stack justifyContent="center" alignItems="flex-start">
              <Typography fontWeight={500}>Click to upload or drag and drop</Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: "grey.A200",
                }}
              >
                Max file size is {maxSize / 1024 / 1024} MB
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}

      {/* file previews */}
      {uploadedFiles && <FilePreviews deleteFile={onDeleteFile} files={uploadedFiles} sx={{ mt: 1 }} />}

      {/* loading state */}
      {loading && <CircularProgress />}

      {/* error messages */}
      {uniqueErrors.length > 0 && (
        <Grid container>
          {uniqueErrors.map((message) => (
            <Grid item key={message} xs={12}>
              <Typography variant="caption" color="error">
                {message}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

const baseStyle = {
  height: "100%",
  padding: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "white",
  transition: "border .24s ease-in-out",
  border: "1px dashed rgba(3, 30, 48, 0.2)",
  borderRadius: "20px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  backgroundColor: "#EBEFF7",
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: alpha("#E9645F", 0.1),
};

export { FileUploadComponent };
