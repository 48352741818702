import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import dayjs from "dayjs";

export type PrivatePolicyStatus = "flagged" | "accepted" | "rejected" | "expired" | "archived" | "pending" | "extracted" | null;

export type PrivatePolicyStatusGroup = PrivatePolicyStatus | "extracted";

export const getCalculatedPrivatePolicyStatus = (
  privatePolicy: SafeleasePrivatePolicy,
): {
  statusGroup: PrivatePolicyStatusGroup;
  status: PrivatePolicyStatusGroup;
  isExpired: boolean;
} => {
  const diffInDays = dayjs(privatePolicy.expiration).diff(dayjs(), "day");

  const status = privatePolicy.archivedAt
    ? "archived"
    : diffInDays < 0
      ? "expired"
      : ["accepted"].includes(privatePolicy.validationStatus)
        ? "accepted"
        : ["rejected", "pendingRejected"].includes(privatePolicy.validationStatus)
          ? "rejected"
          : ["humanReview"].includes(privatePolicy.validationStatus)
            ? "flagged"
            : ["pending"].includes(privatePolicy.validationStatus)
              ? "pending"
              : null;

  const extractedPrivatePolicyStatus = ["accepted"].includes(privatePolicy.validationStatus)
    ? "accepted"
    : ["rejected", "pendingRejected"].includes(privatePolicy.validationStatus)
      ? "rejected"
      : ["humanReview"].includes(privatePolicy.validationStatus)
        ? "flagged"
        : ["pending"].includes(privatePolicy.validationStatus)
          ? "pending"
          : null;

  const statusGroup = privatePolicy.extractedPrivatePolicyId ? "extracted" : status;

  return {
    status: privatePolicy.extractedPrivatePolicyId ? extractedPrivatePolicyStatus : status,
    statusGroup,
    isExpired: diffInDays < 0,
  };
};
