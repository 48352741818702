import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { ReactElement } from "react";
import { Control, Controller } from "react-hook-form";

type StyledToolEnabledToggleFormElementProps = {
  icon: ReactElement;
  name:
    | "isJobCenterEnabled"
    | "isClaimsEnabled"
    | "isComplianceEnabled"
    | "isUserSettingsAdmin"
    | "isTenantUserAdmin"
    | "isDebuggingEnabled"
    | "needsPasswordReset"
    | "isEmailVerified"
    | "isAdminReqsEnabled"
    | "isProspectsEnabled"
    | "isTodoListEnabled"
    | "isPcEnabled"
    | "hasAccessToFmsCreds";
  label: string;
  control: Control<any, any>;
};

function StyledToolEnabledToggleFormElement({ icon, name, label, control }: StyledToolEnabledToggleFormElementProps) {
  function stringToBoolean(str: "true" | "false"): boolean {
    switch (str) {
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon}
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={stringToBoolean(field.value)}
                onChange={(e) => {
                  field.onChange(e.target.checked.toString());
                }}
              />
            )}
          />
        }
      />
    </Stack>
  );
}

export { StyledToolEnabledToggleFormElement };
