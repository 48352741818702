import { useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { SafeLeaseChip, SafeLeaseDataGrid } from "@safelease/components";
import { TableZeroState } from "../pages/TableZeroState";
import { todoColumns } from "../shared/columnDefs";
import { toTitleCase } from "../../../utils/helpers/to-title-case";
import { useProtection } from "./useProtection";
import { SafeLeaseLinearProgress as Loader } from "../../../components/SafeLeaseLinearProgress";
import { SelectedTodo } from "./useProtection";
import { Todo, GetLocationsWithPendingTodosResponse } from "@safelease/service-utilities";
import { GridRowId, GridRowParams } from "@mui/x-data-grid-premium";
import { transformTypeToMatchFms } from "../../../utils/helpers/transform-type-match-fms";
import Error from "../../../components/SafeLeaseError";

interface ProtectionTableProps {
  // TODO: Update typing
  updateTodoMutation: any;
}

const StyledTypography = styled(Typography)`
  font-size: 14px;
  color: grey;
  font-weight: 500;
`;

const statusColors: { [key: string]: "default" | "error" | "warning" | "success" | "primary" | "secondary" | "info" } = {
  pending: "warning",
  approved: "success",
  rejected: "error",
};

function ProtectionTable({ updateTodoMutation }: ProtectionTableProps) {
  const { setSelectedTodo, getTodosQuery } = useProtection();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  if (getTodosQuery.isLoading || updateTodoMutation.isPending) {
    return <Loader />;
  }

  if (getTodosQuery.isError || updateTodoMutation.isError) {
    return <Error />;
  }

  const handleRowClick = (params: GridRowParams) => {
    const rowId = params.row.id;
    setExpandedRowIds((prev: any) => {
      if (prev.includes(rowId)) {
        return prev.filter((id: string) => id !== rowId);
      }
      return [...prev, rowId];
    });
  };

  const handleUnitRowClick = (params: GridRowParams, todoId: number) => {
    // transform the todo object to include locationName and locationAddress
    const todo: SelectedTodo = Object.assign(
      params.row.todos.find((todo: Todo) => todo.id === todoId),
      { locationName: params.row.locationName, locationAddress: params.row.locationAddress },
    );
    setSelectedTodo(todo);
  };

  const mapTodos = (todos: GetLocationsWithPendingTodosResponse[]) => {
    return todos?.map((todo: GetLocationsWithPendingTodosResponse) => {
      return {
        // mui data grid requires an id field | map the locationId to id
        id: todo.locationId,
        ...todo,
      };
    });
  };

  const sortTodosByUnitNameAndType = (todos: Todo[]) => {
    return todos.sort((a: Todo, b: Todo) => {
      if (a.payload?.unitName && b.payload?.unitName) {
        return a.payload.unitName.localeCompare(b.payload.unitName);
      }
      return a.type.localeCompare(b.type);
    });
  };

  const createDetailPanelContent = (params: GridRowParams) => {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
        }}
        direction="column"
      >
        {sortTodosByUnitNameAndType(params.row.todos).map((todo: Todo) => {
          const payload = todo.payload;

          return (
            <Box
              key={todo.id}
              sx={{
                display: "flex",
                padding: 1,
                borderRadius: 2, // Rounded corners
                border: "1px solid #ddd", // Light border
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow
                transition: "all 0.3s ease", // Smooth transition for hover effects
                ":hover": {
                  backgroundColor: "#f9f9f9", // Lighter background on hover
                  cursor: "pointer", // Pointer cursor
                  transform: "translateY(-2px)", // Slightly lift the box
                  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)", // Enhanced shadow on hover
                },
                mb: "8px",
              }}
              onClick={() => handleUnitRowClick(params, todo.id)}
            >
              <Grid item xs={4}>
                <StyledTypography>
                  Unit Number:
                  <Typography component="span" sx={{ ml: 1, fontWeight: "bold", fontSize: "14px" }}>
                    {payload?.unitName}
                  </Typography>
                </StyledTypography>
              </Grid>
              <Grid item xs={4}>
                <StyledTypography>
                  Action:
                  <Typography component="span" sx={{ ml: 1, fontWeight: "bold", fontSize: "14px" }}>
                    {transformTypeToMatchFms(todo.type)}
                  </Typography>
                </StyledTypography>
              </Grid>
              <Grid item xs={4}>
                <SafeLeaseChip label={toTitleCase(todo.status)} color={statusColors[todo.status]} />
              </Grid>
            </Box>
          );
        })}
      </Grid>
    );
  };

  return (
    <SafeLeaseDataGrid
      rows={mapTodos(getTodosQuery?.data || [])}
      columns={todoColumns}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={getTodosQuery?.data?.length || 0}
      getRowId={(row) => row.id}
      sx={{
        background: "white",
        borderColor: "#EBEFF7",
        height: "100%",
        "& .MuiDataGrid-cell": {
          borderColor: "#EBEFF7",
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        minHeight: "200px",
      }}
      slots={{
        noRowsOverlay: () => <TableZeroState message={"No data available."} />,
      }}
      hideFooter={false}
      detailPanelExpandedRowIds={expandedRowIds}
      // Expanded row content
      getDetailPanelContent={createDetailPanelContent}
      onRowClick={handleRowClick}
      // Expanded panel height
      getDetailPanelHeight={(params) => {
        const baseHeight = 60; // Base height for an empty panel
        const rowHeight = 50; // Height per todo item
        const maxHeight = 300; // Maximum height before it becomes scrollable

        const todoCount = params.row.todos.length;
        const calculatedHeight = baseHeight + todoCount * rowHeight;

        return Math.min(calculatedHeight, maxHeight);
      }}
      // Expand or collapse row when icon is clicked
      onDetailPanelExpandedRowIdsChange={(newExpandedRowIds: GridRowId[]) => {
        setExpandedRowIds(newExpandedRowIds);
      }}
    />
  );
}

export { ProtectionTable };
