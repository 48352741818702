// UI - Libs
import { Box, Dialog, Divider, Grid, IconButton, Stack, Theme, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

// UI - Internal
import { PrivatePolicyReviewForm } from "./PrivatePolicyReviewForm";
import { PrivatePolicyStatusChip } from "./PrivatePolicyStatusChip";
import { PDFViewer } from "../../../components/PDFViewer/PDFViewer";

// Hooks
import { useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useSnackbar } from "notistack";

// Data
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { PrivatePolicyValidationStatus } from "../useReportStore/privatePolicyReportSlice";
import { PrivatePolicyApi } from "../../../utils/apiInstances/PrivatePolicyApiInstance";
import { ReviewPrivatePolicyActions } from "./ReviewPrivatePolicyActions";
import { PrivatePolicyDetailsList } from "./PrivatePolicyDetailsList";
import { PrivatePolicyNotesEditor } from "./PrivatePolicyNotesEditor";
import { useComplianceCenterStore } from "./useComplianceCenter";
import { useEffect } from "react";

export type FieldType = "tenantName" | "policyNumber" | "expirationDate";
export type FieldValueSource = "userInput" | "aiExtracted" | "manualOverride";
export type RejectionReason = "invalidDeclarationPage" | "invalidPolicyNumber" | "invalidExpiration" | "invalidTenantName" | "other";

interface ReviewPrivatePolicyDialogProps {
  open: boolean;
  privatePolicy: SafeleasePrivatePolicy;
  handleClose: () => void;
}

/* A dialog containing a PDF viewer and a form to review and accept or reject a private policy */
export function ReviewPrivatePolicyDialog({ open, privatePolicy, handleClose }: ReviewPrivatePolicyDialogProps) {
  const methods = useForm({});
  const { enqueueSnackbar } = useSnackbar();
  const privatePolicies = useComplianceCenterStore((state) => state.privatePolicies);
  const updatePrivatePolicy = useComplianceCenterStore((state) => state.updatePrivatePolicy);
  const isArchived = !!privatePolicy?.archivedAt;

  const fetch = async function () {
    if (!privatePolicy) return null;

    const result = await PrivatePolicyApi.getEphemeralLink({ privatePolicyId: privatePolicy.id.toString() });
    return result.data;
  };

  const ephemeralLink = useQuery({
    queryKey: ["get-ephemeral-link", privatePolicy?.id],
    queryFn: () => fetch(),
    enabled: !!privatePolicy,
  });

  const [submittingType, setSubmittingType] = useState<PrivatePolicyValidationStatus | null>(null);

  // Reset the form whenever the modal closes
  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [open]);

  /* Archive this private policy */
  const handleArchive = async () => {
    if (window.confirm("Are you sure you want to delete this private policy?")) {
      try {
        await PrivatePolicyApi.updateStatus({
          privatePolicyId: privatePolicy.id,
          archivedAt: new Date(),
        });
        enqueueSnackbar(`Private policy archived`, {
          variant: "success",
        });
        handleClose();
      } catch (err: any) {
        console.error("Error archiving private policy:", err);
        enqueueSnackbar(`Something went wrong while archiving private policy.`, { variant: "error" });
      }
    }
  };

  /* Approve this private policy, tenant will be opted out of tenant protection */
  const handleApprove = async () => {
    const formValues = methods.getValues();

    try {
      setSubmittingType(PrivatePolicyValidationStatus.accepted);
      await PrivatePolicyApi.updateStatus({
        generalUnitId: privatePolicy.generalUnitId,
        privatePolicyId: privatePolicy.id,
        validationStatus: PrivatePolicyValidationStatus.accepted,
        overrideTenantName: formValues.tenantName.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber.manualOverrideValue,
        overrideExpiration: formValues.expirationDate.manualOverrideValue,
        tenantNameSource: formValues.tenantName.fieldSource,
        policyNumberSource: formValues.policyNumber.fieldSource,
        expirationSource: formValues.expirationDate.fieldSource,
        sendEmail: formValues.sendEmail,
      });

      const index = privatePolicies.findIndex((policy) => policy.id === privatePolicy.id);

      const updatedPrivatePolicy = {
        ...privatePolicies[index],
        validationStatus: PrivatePolicyValidationStatus.accepted as string,
        overrideTenantName: formValues.tenantName.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber.manualOverrideValue,
        overrideExpiration: formValues.expirationDate.manualOverrideValue,
        tenantNameSource: formValues.tenantName.fieldSource,
        policyNumberSource: formValues.policyNumber.fieldSource,
        expirationSource: formValues.expirationDate.fieldSource,
        sendEmail: formValues.sendEmail,
      };

      // Update this newly updated private policy locally locally in the private policy list
      updatePrivatePolicy(updatedPrivatePolicy);

      enqueueSnackbar(`Private policy approved`, {
        variant: "success",
      });
      handleClose();
    } catch (err: any) {
      console.log(err);

      enqueueSnackbar(
        <Box>
          <Typography fontWeight={700}>Something went wrong while approving private policy.</Typography>
          <Typography>{err.response?.data?.error}</Typography>
        </Box>,
        { variant: "info" },
      );
    } finally {
      setSubmittingType(null);
    }
  };

  // /* Deny this private policy
  //  * Private policy can be denied for one of many reasons:
  //  * - invalid expiration date
  //  * - invalid policy number
  //  * - invalid person name
  //  * - private policy doesn't cover self storage units
  //  * - declarations page is missing
  //  * - declarations page is not real
  //  */
  const handleReject = async (rejectionReason: RejectionReason, rejectionReasonCustom: string) => {
    const formValues = methods.getValues();

    try {
      setSubmittingType(PrivatePolicyValidationStatus.rejected);
      await PrivatePolicyApi.updateStatus({
        generalUnitId: privatePolicy.generalUnitId,
        privatePolicyId: privatePolicy.id,
        validationStatus: PrivatePolicyValidationStatus.rejected,
        overrideTenantName: formValues.tenantName?.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber?.manualOverrideValue,
        overrideExpiration: formValues.expirationDate?.manualOverrideValue,
        tenantNameSource: formValues.tenantName?.fieldSource,
        policyNumberSource: formValues.policyNumber?.fieldSource,
        expirationSource: formValues.expirationDate?.fieldSource,
        rejectionReason,
        rejectionReasonCustom: rejectionReason == "other" ? rejectionReasonCustom : undefined,
        sendEmail: formValues.sendEmail,
      });

      const index = privatePolicies.findIndex((policy) => policy.id === privatePolicy.id);

      const updatedPrivatePolicy = {
        ...privatePolicies[index],
        validationStatus: PrivatePolicyValidationStatus.rejected as string,
        overrideTenantName: formValues.tenantName?.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber?.manualOverrideValue,
        overrideExpiration: formValues.expirationDate?.manualOverrideValue,
        tenantNameSource: formValues.tenantName?.fieldSource,
        policyNumberSource: formValues.policyNumber?.fieldSource,
        expirationSource: formValues.expirationDate?.fieldSource,
        sendEmail: formValues.sendEmail,
      };

      // Update this newly updated private policy locally locally in the private policy list
      updatePrivatePolicy(updatedPrivatePolicy);

      enqueueSnackbar(`Private policy denied`, {
        variant: "success",
      });
      handleClose();
    } catch (err: any) {
      console.log(err);

      enqueueSnackbar(
        <Box>
          <Typography fontWeight={700}>Something went wrong while denying private policy.</Typography>
          <Typography>{err.response?.data?.error}</Typography>
        </Box>,
        { variant: "info" },
      );
    } finally {
      setSubmittingType(null);
    }
  };

  // None of the fields are checked as "invalid"
  const allFieldsValid =
    methods
      .watch(["tenantName.invalid", "policyNumber.invalid", "expirationDate.invalid"])
      .filter((field: boolean | string | undefined) => !field).length === 3;

  // All the fields have been selected and have a value
  // primarily used for catching if an manually entered value is empty
  const allFieldsSelected =
    methods
      .watch([
        "tenantName.fieldValue",
        "policyNumber.fieldValue",
        "expirationDate.fieldValue",
        "tenantName.manualOverrideValue",
        "policyNumber.manualOverrideValue",
        "expirationDate.manualOverrideValue",
      ])
      .filter((field: string | undefined) => (field ? field.length > 0 : false)).length >= 3;

  // All field sources are not "invalid" i.e. the user last checked one of the rows
  const allFieldSourcesValid =
    methods
      .watch(["tenantName.fieldSource", "policyNumber.fieldSource", "expirationDate.fieldSource"])
      .filter((fieldSource: string | undefined) => (fieldSource ? fieldSource.length > 0 : false) && fieldSource !== "invalid").length ===
    3;

  const needsReview = privatePolicy?.validationStatus === "humanReview" || !privatePolicy?.validationStatus;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          p: 4,
        },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={() => null}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h5">Submission ID: {privatePolicy?.id}</Typography>
                  {privatePolicy && <PrivatePolicyStatusChip privatePolicy={privatePolicy} showInfo />}
                </Stack>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ maxHeight: 700, overflow: "auto" }}>
              {needsReview && <PrivatePolicyReviewForm privatePolicy={privatePolicy} />}
              {!needsReview && privatePolicy && <PrivatePolicyDetailsList privatePolicy={privatePolicy} />}
              <PrivatePolicyNotesEditor privatePolicy={privatePolicy} />
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: (theme: Theme) => theme.palette.divider,
                  width: "100%",
                  height: 617,
                  overflow: "hidden",
                  boxShadow: "0 0 20px rgba(0,0,0,0.05)",
                }}
              >
                <PDFViewer file={ephemeralLink?.data?.url} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ bgcolor: (theme: Theme) => theme.palette.divider }} />
            </Grid>
            <Grid item xs={12}>
              <ReviewPrivatePolicyActions
                handleApprove={handleApprove}
                handleReject={handleReject}
                handleArchive={handleArchive}
                submittingType={submittingType}
                disablePrimaryActions={
                  privatePolicy?.validationStatus === PrivatePolicyValidationStatus.accepted ||
                  privatePolicy?.validationStatus === PrivatePolicyValidationStatus.rejected ||
                  privatePolicy?.validationStatus === PrivatePolicyValidationStatus.archived
                }
                allFieldsValid={allFieldsValid}
                allFieldsSelected={allFieldsSelected}
                allFieldSourcesValid={allFieldSourcesValid}
                isArchived={isArchived}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Dialog>
  );
}
