import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { appConfig } from "../config/app.config";
import { getValidToken, useAuthStore } from "./useAuthStore";

const EXTENSION_ID = "gaeahfnmanflkoeckiegdhonkdfmnjgl";

interface FmsLoginPayload {
  locationId?: number;
  accountId?: number;
}

interface UseFmsLoginExtensionReturn {
  chromeExtensionInstalled: boolean;
  isLoading: boolean;
  showButton: boolean; // whether or not the current user has access to FMS creds and is on a chrome browser
  loginToFms: (payload: FmsLoginPayload) => Promise<void>;
}

export function useFmsLoginExtension(): UseFmsLoginExtensionReturn {
  const [chromeExtensionInstalled, setChromeExtensionInstalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userAttributes = useAuthStore((state) => state.userAttributes);

  const isChromeBrowser = typeof chrome !== "undefined";
  const currentUserHasAccessToFmsCreds = userAttributes?.hasAccessToFmsCreds === "true";
  const showButton = currentUserHasAccessToFmsCreds && isChromeBrowser;

  useEffect(() => {
    const checkExtensionInstallation = () => {
      if (!isChromeBrowser || !chrome.runtime?.sendMessage) {
        setChromeExtensionInstalled(false);
        return;
      }
      try {
        // Try to communicate with the extension
        chrome.runtime.sendMessage(EXTENSION_ID, { type: "ping" }, (response) => {
          // If there's no response or error, the extension isn't installed/enabled
          setChromeExtensionInstalled(!!response);
        });
      } catch (error) {
        setChromeExtensionInstalled(false);
      }
    };

    checkExtensionInstallation();
  }, []);

  const loginToFms = async (payload: FmsLoginPayload) => {
    if (!chromeExtensionInstalled) {
      enqueueSnackbar("FMS Login Chrome Extension is not installed", { variant: "error" });
      return;
    }

    if (!payload.locationId && !payload.accountId) {
      enqueueSnackbar("No location or account ID provided", { variant: "error" });
      return;
    }

    const jwtToken = await getValidToken();
    setIsLoading(true);

    try {
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          type: "loginToFMS",
          payload: {
            baseSafeleaseApiUrl: appConfig.privatePolicyApiBaseUrl,
            jwtToken,
            ...payload,
          },
        },
        (response) => {
          setIsLoading(false);

          if (response?.status === "error") {
            enqueueSnackbar("Failed to login to FMS", { variant: "error" });
          }
        },
      );
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Failed to login to FMS", { variant: "error" });
    }
  };

  return {
    chromeExtensionInstalled,
    isLoading,
    showButton,
    loginToFms,
  };
}
