import { Card, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { AccountOptionPayload } from "../FmsLoginButtonWithBackupAccountOptions";
import { useQuery } from "@tanstack/react-query";
import { SafeleaseApi } from "../../../utils/apiInstances/SafeleaseApiInstance";
import PasswordCopyText from "./PasswordCopyText";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

type ResetPasswordModalProps = {
  setModalOpen: (open: boolean) => void;
  accountOptionLabel: string;
  accountOptionPayload: AccountOptionPayload;
};

function ResetPasswordModal({ setModalOpen, accountOptionLabel, accountOptionPayload }: ResetPasswordModalProps) {
  const [isSavingPasswordLoading, setIsSavingPasswordLoading] = useState(false);
  const { accountId, locationId } = accountOptionPayload;

  const currentAccountCredentialsQuery = useQuery({
    queryKey: ["accountCredentials", accountId, locationId],
    queryFn: async () => {
      const response = await SafeleaseApi.getAccountCredentials({
        ...accountOptionPayload,
        message: "Reset password modal from login to fms button.", // message is used by the backend to log credential access for fms credentials
      });
      return response.data;
    },
  });

  const getNewPasswordForFmsUserQuery = useQuery({
    queryKey: ["getNewPasswordForFmsUser", accountId, locationId],
    queryFn: async () => {
      const response = await SafeleaseApi.getNewPasswordForFmsUser();
      return response.data;
    },
  });

  const handleSavePassword = async () => {
    const confirm = window.confirm("Are you sure that the password was correctly updated on the FMS?");
    if (!confirm) return;
    if (!getNewPasswordForFmsUserQuery.data?.password) return;

    setIsSavingPasswordLoading(true);
    try {
      await SafeleaseApi.updatePasswordForAccount({
        ...accountOptionPayload,
        password: getNewPasswordForFmsUserQuery.data?.password,
      });
      enqueueSnackbar("Password updated successfully", { variant: "success" });
      setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Error updating password", { variant: "error" });
    } finally {
      setIsSavingPasswordLoading(false);
    }
  };

  return (
    <Modal open={true} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card sx={{ padding: 2, display: "flex", flexDirection: "column", maxHeight: "80vh" }}>
        {currentAccountCredentialsQuery.isLoading || getNewPasswordForFmsUserQuery.isLoading ? (
          <CircularProgress />
        ) : !currentAccountCredentialsQuery.data?.credentials?.password || !getNewPasswordForFmsUserQuery.data ? (
          <Typography>Error fetching credential data</Typography>
        ) : currentAccountCredentialsQuery.data.type !== "storedge" ? (
          <Typography>Updating password for {currentAccountCredentialsQuery.data.type} is not supported.</Typography>
        ) : (
          <Stack spacing={2}>
            <Typography variant="h6">{accountOptionLabel}</Typography>
            <PasswordCopyText type="original" password={currentAccountCredentialsQuery.data.credentials.password} />
            <PasswordCopyText type="new" password={getNewPasswordForFmsUserQuery.data?.password} />
            <Stack direction="row" spacing={2}>
              <SafeLeaseButton variant="outlined" color="navy" onClick={() => setModalOpen(false)}>
                Cancel
              </SafeLeaseButton>
              <SafeLeaseButton variant="contained" color="error" onClick={handleSavePassword} loading={isSavingPasswordLoading}>
                Save Password
              </SafeLeaseButton>
            </Stack>
          </Stack>
        )}
      </Card>
    </Modal>
  );
}

export { ResetPasswordModal };
