import { Box, CircularProgress, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { useFmsLoginExtension } from "../../hooks/useFmsLoginChromeExtension";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { OpenInNewOutlined } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { SafeleaseApi } from "../../utils/apiInstances/SafeleaseApiInstance";
import { ResetPasswordModal } from "./ResetPasswordModal/ResetPasswordModal";

export interface AccountOptionPayload {
  locationId?: number;
  accountId?: number;
}

type FmsLoginButtonWithBackupAccountOptionsProps = {
  locationId: number;
};

function FmsLoginButtonWithBackupAccountOptions({ locationId }: FmsLoginButtonWithBackupAccountOptionsProps) {
  const [selectedAccountOption, setSelectedAccountOption] = useState<string>("primary");
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const { chromeExtensionInstalled, isLoading, showButton, loginToFms } = useFmsLoginExtension();

  const standbyAccountsQuery = useQuery({
    queryKey: ["getStandbyAccountsForLocation", locationId],
    queryFn: async () => {
      const response = await SafeleaseApi.getStandbyAccountsForLocation(locationId);
      return response.data;
    },
  });

  const getAccountOptionLabel = (accountOption: string) => {
    if (accountOption === "primary") return "Primary account";
    return `Backup account ${accountOption}`;
  };

  const getAccountOptionPayload = () => {
    if (selectedAccountOption === "primary") return { locationId };
    const currentSelectedAccountId = standbyAccountsQuery.data?.find((account) => account.indexKey === selectedAccountOption)?.id;
    return { accountId: currentSelectedAccountId };
  };

  const handleLogin = async () => {
    await loginToFms(getAccountOptionPayload());
  };

  if (!showButton) {
    return null;
  }

  if (standbyAccountsQuery.isLoading) {
    return <CircularProgress />;
  }

  if (!standbyAccountsQuery.data) return <div>Error fetching standby accounts</div>;

  return (
    <Stack direction="row" spacing={2}>
      <Select value={selectedAccountOption} onChange={(e) => setSelectedAccountOption(e.target.value)}>
        <MenuItem value="primary">{getAccountOptionLabel("primary")}</MenuItem>
        {standbyAccountsQuery.data?.map((account) => (
          <MenuItem key={account.indexKey} value={account.indexKey}>
            {getAccountOptionLabel(account.indexKey)}
          </MenuItem>
        ))}
      </Select>
      <Tooltip title={!chromeExtensionInstalled ? "FMS Login Chrome Extension is not installed correctly." : ""}>
        <Box>
          <SafeLeaseButton
            variant="outlined"
            onClick={handleLogin}
            loading={isLoading}
            disabled={!chromeExtensionInstalled}
            endIcon={<OpenInNewOutlined />}
          >
            Login to FMS
          </SafeLeaseButton>
        </Box>
      </Tooltip>
      <SafeLeaseButton
        disabled={!chromeExtensionInstalled}
        variant="outlined"
        color="error"
        onClick={() => setResetPasswordModalOpen(true)}
      >
        Needs password reset
      </SafeLeaseButton>
      {resetPasswordModalOpen && (
        <ResetPasswordModal
          accountOptionPayload={getAccountOptionPayload()}
          accountOptionLabel={getAccountOptionLabel(selectedAccountOption)}
          setModalOpen={setResetPasswordModalOpen}
        />
      )}
    </Stack>
  );
}

export { FmsLoginButtonWithBackupAccountOptions };
