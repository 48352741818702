import { TodoType } from "@safelease/service-utilities/enums";
import { toTitleCase } from "./to-title-case";

export const transformTypeToMatchFms = (type: string) => {
  switch (type) {
    case TodoType.ESS_ENROLL_UNIT:
    case TodoType.STOREDGE_ENROLL_UNIT:
      return "Enroll Safelease Policy";
    case TodoType.ESS_UNENROLL_UNIT:
    case TodoType.STOREDGE_UNENROLL_UNIT:
      return "Unenroll Safelease Policy";
    case TodoType.ESS_ATTACH_PRIVATE_POLICY:
    case TodoType.STOREDGE_ATTACH_PRIVATE_POLICY:
      return "Add Private Policy";
    case TodoType.ESS_REMOVE_PRIVATE_POLICY:
    case TodoType.STOREDGE_REMOVE_PRIVATE_POLICY:
      return "Remove Private Policy";
    default:
      return toTitleCase(type);
  }
};
