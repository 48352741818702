import { CircularProgress, Divider, Drawer, Grid, Typography } from "@mui/material";
import { Todo } from "@safelease/service-utilities";
import IngestionDocumentUploader from "./IngestionDocumentUploader";
import { SafeleaseApi } from "../../../utils/apiInstances/SafeleaseApiInstance";
import { TodoAction, TodoStatus } from "@safelease/service-utilities/enums";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { FmsLoginButtonWithBackupAccountOptions } from "../../../components/FmsLoginButtonWithBackupAccountOptions";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";

interface IngestionTodoDrawerProps {
  locationId: number;
  onClose: () => void;
  refreshTodoList: () => void;
}

function IngestionTodoDrawer({ locationId, onClose, refreshTodoList }: IngestionTodoDrawerProps) {
  const {
    data: todosForLocation,
    isLoading: locationLoading,
    refetch: refetchTodosForLocation,
  } = useQuery({
    queryKey: ["getLocationsWithPendingTodos", locationId],
    queryFn: async () => {
      const response = await SafeleaseApi.getLocationsWithPendingTodos({ action: TodoAction.READ, locationId: locationId.toString() });
      return response.data;
    },
  });

  // close drawer if there are no more todos for the location
  useEffect(() => {
    if (todosForLocation && todosForLocation.length === 0) {
      onClose();
    }
  }, [todosForLocation, onClose]);

  const onSubmitFile = async (file: File, todoId: number) => {
    try {
      const fileName = await SafeleaseApi.directUploadFile({ file });

      await SafeleaseApi.updateTodo({
        todoId: todoId,
        attachment_filename: fileName,
        attachment_desired_filename: file.name,
        status: TodoStatus.SUCCESS,
      });

      refreshTodoList();
      refetchTodosForLocation();
    } catch (error) {
      enqueueSnackbar(error instanceof AxiosError ? error?.response?.data?.error : "Error uploading file", { variant: "error" });
      console.error(error);
    }
  };

  if (locationLoading) return <CircularProgress />;

  return (
    <Drawer anchor="right" open={true} onClose={onClose} PaperProps={{ sx: { width: "50%" } }}>
      <Grid container padding={1} spacing={2}>
        {/* header */}
        <Grid item xs={12}>
          <Typography variant="h6">Report Details</Typography>
        </Grid>

        {/* location details */}
        <Grid item xs={12}>
          <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1">{todosForLocation?.[0]?.locationName || "No location name"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ color: "gray" }}>
                    {todosForLocation?.[0]?.locationAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* login to fms button */}
            {todosForLocation?.[0]?.locationId && (
              <Grid item>
                <FmsLoginButtonWithBackupAccountOptions locationId={todosForLocation[0].locationId} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* document uploaders */}
        <Grid item container>
          {todosForLocation?.[0]?.todos.map((todo: Todo) => {
            return (
              <Grid item key={todo.id} xs={12}>
                <IngestionDocumentUploader key={todo.id} todo={todo} onSubmit={onSubmitFile} todoId={todo.id} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Drawer>
  );
}

export { IngestionTodoDrawer };
