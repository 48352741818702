// UI - libs
import { Box, LinearProgress, Paper, Stack, Typography } from "@mui/material";

// UI - internal
import { SafeLeaseTab, SafeLeaseTabs } from "@safelease/components";
import { complianceCenterGridColDefs } from "./complianceCenterGridColDefs";
import { ReviewPrivatePolicyDialog } from "./ReviewPrivatePolicyDialog";

// Hooks
import { useMemo, useState, useEffect } from "react";
import { useComplianceCenter } from "./useComplianceCenter";
import useWindowSize from "../../../hooks/useWindowSize";

// Data
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";

// Utils
import { blue } from "@mui/material/colors";
import { formatWithCommas } from "../../../utils/formatters";
import { SafeLeaseDataGrid } from "@safelease/components";
import { getCalculatedPrivatePolicyStatus } from "../../../utils/helpers/privatePolicy";

enum ComplianceCenterTabs {
  ALL = "all",
  FLAGGED = "flagged",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  EXPIRED = "expired",
  PENDING = "pending",
  ARCHIVED = "archived",
  EXTRACTED = "extracted",
}

interface ComplianceCenterProps {}

export function ComplianceCenter({}: ComplianceCenterProps) {
  const size = useWindowSize();
  const { privatePolicies } = useComplianceCenter();

  const [selectedPrivatePolicy, setSelectedPrivatePolicy] = useState<SafeleasePrivatePolicy | null>(null);
  const [currentTab, setCurrentTab] = useState<ComplianceCenterTabs>(ComplianceCenterTabs.FLAGGED);
  const [filteredPolicies, setFilteredPolicies] = useState<SafeleasePrivatePolicy[]>([]);

  // Monitor changes in privatePolicies and currentTab and update filteredPolicies
  useEffect(() => {
    const filtered = privatePolicies.filter((privatePolicy: SafeleasePrivatePolicy) =>
      currentTab === ComplianceCenterTabs.ALL ? true : getCalculatedPrivatePolicyStatus(privatePolicy).statusGroup === currentTab,
    );
    setFilteredPolicies(filtered as SafeleasePrivatePolicy[]);
  }, [privatePolicies, currentTab]);

  const handleChangeFilterTab = (_: React.SyntheticEvent, newCurrentTab: ComplianceCenterTabs) => {
    setCurrentTab(newCurrentTab);
  };

  const numFlaggedPolicies = useMemo(() => {
    return privatePolicies.filter(
      (privatePolicy: SafeleasePrivatePolicy) =>
        getCalculatedPrivatePolicyStatus(privatePolicy).statusGroup === ComplianceCenterTabs.FLAGGED,
    ).length;
  }, [privatePolicies]);

  const numFlaggedExtractedPolicies = useMemo(() => {
    return privatePolicies.filter(
      (privatePolicy: SafeleasePrivatePolicy) =>
        getCalculatedPrivatePolicyStatus(privatePolicy).statusGroup === ComplianceCenterTabs.EXTRACTED &&
        getCalculatedPrivatePolicyStatus(privatePolicy).status === ComplianceCenterTabs.FLAGGED,
    ).length;
  }, [privatePolicies]);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          borderRadius: 2,
          bgcolor: (theme) => theme.palette.background.paper,
          p: 2,
          mt: 2,
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ overflow: "visible" }}>
          <SafeLeaseTabs value={currentTab} onChange={handleChangeFilterTab}>
            <SafeLeaseTab
              value={ComplianceCenterTabs.FLAGGED}
              sx={{ overflow: "visible" }}
              label={
                <Typography>
                  Flagged{" "}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      ml: 1,
                      bgcolor: blue[50],
                      color: blue[600],
                      fontWeight: 700,
                      p: "2px 6px",
                      borderRadius: 1,
                      boxShadow: "0 0 20px rgba(0,0,0,0.05)",
                    }}
                  >
                    {formatWithCommas(numFlaggedPolicies)}
                  </Typography>
                </Typography>
              }
            />
            <SafeLeaseTab
              value={ComplianceCenterTabs.EXTRACTED}
              sx={{ overflow: "visible" }}
              label={
                <Typography>
                  Extracted{" "}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      ml: 1,
                      bgcolor: blue[50],
                      color: blue[600],
                      fontWeight: 700,
                      p: "2px 6px",
                      borderRadius: 1,
                      boxShadow: "0 0 20px rgba(0,0,0,0.05)",
                    }}
                  >
                    {formatWithCommas(numFlaggedExtractedPolicies)}
                  </Typography>
                </Typography>
              }
            />
            <SafeLeaseTab value={ComplianceCenterTabs.ACCEPTED} label="Accepted" />
            <SafeLeaseTab value={ComplianceCenterTabs.REJECTED} label="Rejected" />
            <SafeLeaseTab value={ComplianceCenterTabs.EXPIRED} label="Expired" />
            <SafeLeaseTab value={ComplianceCenterTabs.PENDING} label="Pending" />
            <SafeLeaseTab value={ComplianceCenterTabs.ARCHIVED} label="Archived" />
            <SafeLeaseTab value={ComplianceCenterTabs.ALL} label="All" />
          </SafeLeaseTabs>
          <Typography variant="body2">Fetched {privatePolicies.length} private policies</Typography>
        </Stack>
        <Box sx={{ height: size.height - 340, mt: 2 }}>
          <SafeLeaseDataGrid
            getRowId={(row) => row.id}
            sx={{
              borderColor: "#EBEFF7",
              "& .MuiDataGrid-cell": {
                borderColor: "#EBEFF7",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                outline: "none", // Removes cell selection outline
              },
              "& p": {
                // For some reason, by default our footer paragraphs have 1em bottom margin
                marginBottom: 0,
              },
            }}
            pagination
            hideFooter={false}
            pageSizeOptions={[50, 100, 250]}
            hideFooterRowCount={false}
            rows={filteredPolicies}
            columns={complianceCenterGridColDefs}
            autosizeOptions={{
              includeHeaders: true,
              expand: true,
            }}
            onRowClick={({ row }) => setSelectedPrivatePolicy(row as SafeleasePrivatePolicy)}
            initialState={{
              sorting: {
                sortModel: [{ field: "validationStatus", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  rejectionReason: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            slots={{
              loadingOverlay: LinearProgress,
            }}
          />
        </Box>
        {selectedPrivatePolicy && (
          <ReviewPrivatePolicyDialog
            open={Boolean(selectedPrivatePolicy)}
            handleClose={() => setSelectedPrivatePolicy(null)}
            privatePolicy={selectedPrivatePolicy}
          />
        )}
      </Paper>
    </Box>
  );
}
