import { ContentCopy } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

type PasswordCopyTextProps = {
  type: "new" | "original";
  password: string;
};

function PasswordCopyText({ type, password }: PasswordCopyTextProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography>{type === "new" ? "New Password" : "Original Password"}</Typography>
      <Typography sx={{ fontFamily: "password" }}>{"•".repeat(password.length)}</Typography>
      <IconButton onClick={() => navigator.clipboard.writeText(password)}>
        <ContentCopy />
      </IconButton>
    </Stack>
  );
}

export default PasswordCopyText;
