import React, { useState } from "react";
import { SafeLeaseDataGrid } from "@safelease/components";
import { Box, Button, Card, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { useDataChangeManager } from "../useDataChangeManager";
import { SafeLeaseLinearProgress as Loader } from "../../../components/SafeLeaseLinearProgress";
import { toTitleCase } from "../../../utils/helpers/to-title-case";
import Error from "../../../components/SafeLeaseError";
import useWindowSize from "../../../hooks/useWindowSize";
import dayjs from "dayjs";

// Custom empty state component
const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <Typography variant="h6" color="textSecondary">
      No requests found.
    </Typography>
  </Box>
);

function AdminRequestsHistory() {
  const size = useWindowSize();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const { getAdminRequestsByPageQuery, approveAdminRequestMutation, rejectAdminRequestMutation } = useDataChangeManager();
  const {
    data,
    isLoading,
    error,
    refetch: refetchGetAdminRequestsByPageQuery,
  } = getAdminRequestsByPageQuery({
    page: paginationModel.page.toString(),
    limit: paginationModel.pageSize.toString(),
  });

  if (isLoading || approveAdminRequestMutation.isPending || rejectAdminRequestMutation.isPending) {
    return <Loader sx={{ mt: 2 }} />;
  }

  if (error || approveAdminRequestMutation.isError || rejectAdminRequestMutation.isError) {
    return <Error />;
  }

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params: any) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };

        const handleApprove = () => {
          approveAdminRequestMutation.mutate(
            { requestId: params.row.id },
            {
              onSuccess: () => {
                refetchGetAdminRequestsByPageQuery();
              },
            },
          );
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        const handleReject = () => {
          rejectAdminRequestMutation.mutate(
            { requestId: params.row.id },
            {
              onSuccess: () => {
                refetchGetAdminRequestsByPageQuery();
              },
            },
          );
        };

        switch (params.value) {
          case "pending":
            return (
              <>
                <Button variant="contained" size="small" onClick={handleClick}>
                  Action required
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem onClick={handleApprove}>Approve</MenuItem>
                  <MenuItem onClick={handleReject}>Reject</MenuItem>
                </Menu>
              </>
            );
          case "approved":
            return <Chip label="Approved" color="success" />;
          case "rejected":
            return <Chip label="Rejected" color="error" />;
          case "failed":
            return <Chip label="Failed" color="error" />;
          default:
            return null; // Handle other states if necessary
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: any) => {
        const formattedAction = toTitleCase(params.value);
        return <span>{formattedAction}</span>;
      },
    },
    { field: "note", headerName: "Note", width: 300 },
    {
      field: "inputData",
      headerName: "Input Data",
      width: 240,
      valueGetter: (params: any) => JSON.stringify(params.row.inputData),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      valueGetter: (params: any) => dayjs(params.row.createdAt).format("YYYY-MM-DD hh:mm:ss A"),
    },
  ];

  return (
    <Card
      sx={{
        height: size.height - 360,
        width: size.width - 60,
        display: "flex",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <div style={{ flex: 1, overflow: "auto" }}>
        <SafeLeaseDataGrid
          rows={data?.requests || []} // Data from the API
          columns={columns} // Columns for the DataGrid
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel} // Handle page and pageSize changes
          rowCount={data?.totalRows} // Total number of rows
          pagination // Enable pagination
          paginationMode="server"
          pageSizeOptions={[10, 25, 50]} // Options for page size in the pagination bar
          getRowId={(row) => row.id} // Ensure rows are uniquely identified
          sx={{
            borderColor: "#EBEFF7",
            // Set the height and overflow for the DataGrid
            height: "100%", // Ensure it fills the Card height
            "& .MuiDataGrid-cell": {
              borderColor: "#EBEFF7",
              cursor: "pointer",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none", // Removes cell selection outline
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          hideFooter={false}
        />
      </div>
    </Card>
  );
}

export { AdminRequestsHistory };
