import { GridCellParams } from "@mui/x-data-grid-premium";

export const todoColumns = [
  { field: "locationId", headerName: "Location ID", width: 250 },
  { field: "relationshipName", headerName: "Relationship Name", width: 250 },
  {
    field: "locationName",
    headerName: "Location Name",
    width: 250,
    renderCell: (params: GridCellParams) => params.row.locationName || "-",
  },
  {
    field: "locationAddress",
    headerName: "Location Address",
    width: 250,
    renderCell: (params: GridCellParams) => params.row.locationAddress || "-",
  },
  { field: "requests", headerName: "Requests", width: 250, valueGetter: (params: GridCellParams) => params.row.todos?.length || 0 },
];
