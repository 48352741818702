import { ProtectionTable } from "./ProtectionTable";
import { ProtectionDetailsDrawer } from "./ProtectionDetailsDrawer";
import { useProtection } from "./useProtection";

function ProtectionTabContainer() {
  const { updateTodoMutation } = useProtection();

  return (
    <>
      <ProtectionTable updateTodoMutation={updateTodoMutation} />

      {/* Details Drawer */}
      <ProtectionDetailsDrawer updateTodoMutation={updateTodoMutation} />
    </>
  );
}

export { ProtectionTabContainer };
