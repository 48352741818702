import { ContentCopyOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

function InstructionsSection({ label, value, copyable = false }: { label: string; value: string; copyable?: boolean }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipboard = () => {
    // Add the value to the clipboard
    navigator.clipboard.writeText(value);
    enqueueSnackbar("Copied to clipboard", { variant: "success" });
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="column">
        <Typography variant="subtitle2" sx={{ color: "grey" }}>
          {label}
        </Typography>
        <Typography variant="body1" fontWeight="700">
          {value}
        </Typography>
      </Stack>
      {copyable && (
        <Button startIcon={<ContentCopyOutlined />} variant="outlined" size="small" color="primary" onClick={handleCopyToClipboard}>
          Copy
        </Button>
      )}
    </Stack>
  );
}

export { InstructionsSection };
