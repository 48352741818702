import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { CheckBox, DisabledByDefaultRounded } from "@mui/icons-material";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-premium";

const booleanFieldRenderCell = (params: GridRenderCellParams<UserType, any, any, GridTreeNodeWithRender>) => {
  return params.value === "true" ? <CheckBox sx={{ color: "green.main" }} /> : <DisabledByDefaultRounded color="error" />;
};

export const columnDefs: GridColDef<UserType>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => {
      const firstName = params.row.Attributes?.find((attr) => attr.Name === "given_name")?.Value ?? "";
      const lastName = params.row.Attributes?.find((attr) => attr.Name === "family_name")?.Value ?? "";
      return `${firstName} ${lastName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "email")?.Value ?? "";
    },
  },
  {
    field: "userStatus",
    headerName: "User status",
    flex: 1,
    valueGetter: (params) => {
      return params.row.UserStatus;
    },
  },
  {
    field: "claimsEnabled",
    headerName: "Claims enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isClaimsEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "complianceEnabled",
    headerName: "Private policy compliance enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isComplianceEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "jobCenterEnabled",
    headerName: "Job error center enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isJobCenterEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "userSettingsAdmin",
    headerName: "Internal user admin",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isUserSettingsAdmin")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "tenantUserAdmin",
    headerName: "Tenant user admin",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isTenantUserAdmin")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "adminReqsEnabled",
    headerName: "Admin requests enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isAdminReqsEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "prospectsEnabled",
    headerName: "Prospects enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isProspectsEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "todoListEnabled",
    headerName: "Todo list enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isTodoListEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "pcEnabled",
    headerName: "P&C center enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isPcEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "hasAccessToFmsCreds",
    headerName: "Access to FMS credentials",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:hasAccessToFmsCreds")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
];
