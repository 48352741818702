// React
import { useState } from "react";

// UI - Libs
import { Typography, Popover, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Button, Grid } from "@mui/material";

// hooks
import { RejectionReason } from "./ReviewPrivatePolicyDialog";
import { PrivatePolicyRejectionReason } from "@safelease/service-utilities/enums";

interface RejectPrivatePolicyMenuProps {
  isOpen: boolean;
  setRejectAnchorEl: (value: HTMLElement | null) => void;
  rejectAnchorEl: HTMLElement | null;
  handleReject: (rejectionReason: RejectionReason, rejectionReasonCustom: string) => void;
}

/* Menu of reasons to reject a private policy, selecting any kick starts the policy rejection process */
export function RejectPrivatePolicyMenu({ isOpen, setRejectAnchorEl, rejectAnchorEl, handleReject }: RejectPrivatePolicyMenuProps) {
  const [rejectionReason, setRejectionReason] = useState("invalidDeclarationPage" as RejectionReason);
  const [rejectionReasonCustom, setRejectionReasonCustom] = useState("");

  const handleSubmit = () => {
    handleReject(rejectionReason, rejectionReasonCustom);
    setRejectAnchorEl(null);
  };

  const handleChoice = (e: any) => {
    const value = e.target.attributes.value.value;
    setRejectionReason(value);
  };

  const cancel = () => {
    setRejectAnchorEl(null);
  };

  return (
    <Popover open={isOpen} anchorEl={rejectAnchorEl} onClose={() => setRejectAnchorEl(null)}>
      <Typography sx={{ p: 2 }}>
        <FormControl>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>Reason for rejecting</Typography>
          <RadioGroup defaultValue={rejectionReason}>
            <FormControlLabel
              onChange={handleChoice}
              value="invalidDeclarationPage"
              control={<Radio />}
              label="Invalid or Missing Declaration Page"
            />
            <FormControlLabel
              onChange={handleChoice}
              value={PrivatePolicyRejectionReason.INVALID_TENANT_NAME}
              control={<Radio />}
              label="Invalid Tenant Name"
            />
            <FormControlLabel
              onChange={handleChoice}
              value={PrivatePolicyRejectionReason.INVALID_POLICY_NUMBER}
              control={<Radio />}
              label="Invalid Policy Number"
            />
            <FormControlLabel
              onChange={handleChoice}
              value={PrivatePolicyRejectionReason.INVALID_EXPIRATION}
              control={<Radio />}
              label="Invalid Policy Expiration Date"
            />
            <FormControlLabel
              onChange={handleChoice}
              value={PrivatePolicyRejectionReason.CORRUPT_FILE}
              control={<Radio />}
              label="Corrupt File"
            />
            <FormControlLabel onChange={handleChoice} value={PrivatePolicyRejectionReason.OTHER} control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        {rejectionReason == "other" && (
          <Typography sx={{ p: 2 }}>
            <TextField
              value={rejectionReasonCustom}
              onChange={(e) => setRejectionReasonCustom(e.target.value)}
              label="Notes"
              sx={{ width: "100%" }}
            ></TextField>
          </Typography>
        )}
        <Grid container justifyContent="flex-end">
          <Button onClick={cancel}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Reject application
          </Button>
        </Grid>
      </Typography>
    </Popover>
  );
}
