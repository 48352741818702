import React from "react";
import { Divider, Drawer, Stack, TextField, Typography } from "@mui/material";
import { useProtection } from "./useProtection";
import { Check, Close } from "@mui/icons-material";
import { SafeLeaseButton } from "@safelease/components";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { TodoStatus, TodoType } from "@safelease/service-utilities/enums";
import { InstructionsSection } from "./InstructionsSection";
import { SafeLeaseLinearProgress } from "../../../components/SafeLeaseLinearProgress";
import { transformTypeToMatchFms } from "../../../utils/helpers/transform-type-match-fms";
import { FmsLoginButtonWithBackupAccountOptions } from "../../../components/FmsLoginButtonWithBackupAccountOptions";
import dayjs from "dayjs";

interface ProtectionDetailsDrawerProps {
  // TODO: Update typing
  updateTodoMutation: any;
}

export function ProtectionDetailsDrawer({ updateTodoMutation }: ProtectionDetailsDrawerProps) {
  const { selectedTodo, notes, setNotes, setSelectedTodo } = useProtection();

  if (!selectedTodo) {
    return null;
  }

  const handleDrawerClose = () => setSelectedTodo(null);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleMarkAsCompleted = () => {
    updateTodoMutation.mutate({
      todoId: selectedTodo.id,
      status: TodoStatus.SUCCESS,
      notes: notes,
    });

    handleDrawerClose();
  };

  const handleMarkAsFailed = () => {
    updateTodoMutation.mutate({
      todoId: selectedTodo.id,
      status: TodoStatus.FAILED,
      notes: notes,
    });

    handleDrawerClose();
  };

  const createInstructionsPerType = (selectedTodo: any) => {
    switch (selectedTodo.type) {
      case TodoType.ESS_ENROLL_UNIT:
      case TodoType.STOREDGE_ENROLL_UNIT:
        return (
          <>
            <InstructionsSection label="Unit Name" value={selectedTodo.payload.unitName} copyable />
            <InstructionsSection label="Tenant Name" value={selectedTodo.payload.tenantName} copyable />
            <InstructionsSection
              label="Plan to enroll in"
              value={`${formatCurrency(selectedTodo.payload.premium / 100)} premium / ${formatCurrency(selectedTodo.payload.coverage / 100)} coverage`}
            />
          </>
        );
      case TodoType.ESS_UNENROLL_UNIT:
      case TodoType.STOREDGE_UNENROLL_UNIT:
        return (
          <>
            <InstructionsSection label="Unit Name" value={selectedTodo.payload.unitName} copyable />
            <InstructionsSection label="Tenant Name" value={selectedTodo.payload.tenantName} copyable />
          </>
        );
      case TodoType.ESS_REMOVE_PRIVATE_POLICY:
      case TodoType.STOREDGE_REMOVE_PRIVATE_POLICY:
        return (
          <>
            <InstructionsSection label="Unit Name" value={selectedTodo.payload.unitName} copyable />
            <InstructionsSection label="Tenant Name" value={selectedTodo.payload.tenantName} copyable />
          </>
        );
      case TodoType.ESS_ATTACH_PRIVATE_POLICY:
      case TodoType.STOREDGE_ATTACH_PRIVATE_POLICY:
        return (
          <>
            <InstructionsSection label="Unit Name" value={selectedTodo.payload.unitName} copyable />
            <InstructionsSection label="Tenant Name" value={selectedTodo.payload.tenantName} copyable />
            <InstructionsSection label="Policy Number" value={selectedTodo.payload.policyNumber} copyable />
            <InstructionsSection
              label="Policy Expiration"
              value={selectedTodo.payload.policyExpiration ? dayjs(selectedTodo.payload.policyExpiration).format("MM/DD/YYYY") : "N/A"}
              copyable
            />
          </>
        );
    }
  };

  return (
    <Drawer anchor="right" onClose={handleDrawerClose} open={!!selectedTodo} PaperProps={{ sx: { width: "50%", p: 2 } }}>
      <Stack>
        <Typography variant="h6">{transformTypeToMatchFms(selectedTodo.type)} Details</Typography>
      </Stack>
      {updateTodoMutation.isPending && <SafeLeaseLinearProgress />}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Typography variant="body1">{selectedTodo.locationName}</Typography>
          <Typography variant="caption" sx={{ color: "gray" }}>
            {selectedTodo.locationAddress}
          </Typography>
        </Stack>
        <FmsLoginButtonWithBackupAccountOptions locationId={selectedTodo.locationId} />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={4}>{createInstructionsPerType(selectedTodo)}</Stack>
      <Divider sx={{ my: 2 }} />
      <Stack>
        <TextField
          name="notes"
          placeholder="Notes here"
          required
          fullWidth
          label="Notes"
          multiline
          rows={4}
          variant="outlined"
          sx={{ m: 1 }}
          value={notes}
          onChange={handleTextChange}
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={2} direction="row" alignItems="center">
        <SafeLeaseButton variant="contained" color="primary" endIcon={<Check />} sx={{ flex: 1 }} onClick={handleMarkAsCompleted}>
          Mark as completed
        </SafeLeaseButton>
        <SafeLeaseButton variant="contained" color="error" endIcon={<Close />} sx={{ flex: 1 }} onClick={handleMarkAsFailed}>
          Mark as failed
        </SafeLeaseButton>
      </Stack>
    </Drawer>
  );
}
