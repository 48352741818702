import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControlLabel, Stack, Switch, Theme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { PrivatePolicyValidationStatus } from "../useReportStore/privatePolicyReportSlice";
import { blue, red } from "@mui/material/colors";
import { RejectPrivatePolicyMenu } from "./RejectPrivatePolicyMenu";
import { useState } from "react";
import { RejectionReason } from "./ReviewPrivatePolicyDialog";

interface ReviewPrivatePolicyActionsProps {
  handleReject: (reason: RejectionReason, rejectionReasonCustom: string) => void;
  handleApprove: () => void;
  handleArchive: () => void;
  submittingType: PrivatePolicyValidationStatus | null;
  disablePrimaryActions: boolean;
  allFieldsSelected: boolean;
  allFieldsValid: boolean;
  allFieldSourcesValid: boolean;
  isArchived: boolean;
}

export function ReviewPrivatePolicyActions({
  handleReject,
  handleApprove,
  handleArchive,
  submittingType,
  disablePrimaryActions,
  allFieldsSelected,
  allFieldsValid,
  allFieldSourcesValid,
  isArchived,
}: ReviewPrivatePolicyActionsProps) {
  const { register } = useFormContext();

  const [rejectAnchorEl, setRejectAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(rejectAnchorEl);

  // Open the rejection menu. Actual private policy rejection happens from the menu items
  const handleRejectMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setRejectAnchorEl(event.currentTarget);
  };

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={2}>
        {!disablePrimaryActions && (
          <FormControlLabel
            label="Notify tenant via email"
            slotProps={{
              typography: {
                sx: {
                  fontSize: "14px",
                  color: (theme: Theme) => theme.palette.grey[500],
                  fontFamily: "Open Sans",
                },
              },
            }}
            control={<Switch defaultChecked={true} {...register("sendEmail")} />}
          />
        )}
        <LoadingButton
          sx={{
            bgcolor: red[500],
            color: "white",
            textTransform: "none",
            fontSize: "14px",
            "&:hover": {
              bgcolor: red[600],
            },
            "&:disabled": {
              bgcolor: (theme: Theme) => theme.palette.grey[100],
              color: (theme: Theme) => theme.palette.grey[500],
            },
          }}
          disabled={isArchived || submittingType !== null}
          onClick={handleArchive}
        >
          Archive
        </LoadingButton>
        <LoadingButton
          sx={{
            bgcolor: red[500],
            color: "white",
            textTransform: "none",
            fontSize: "14px",
            "&:hover": {
              bgcolor: red[600],
            },
            "&:disabled": {
              bgcolor: (theme: Theme) => theme.palette.grey[100],
              color: (theme: Theme) => theme.palette.grey[500],
            },
          }}
          loading={submittingType === PrivatePolicyValidationStatus.rejected}
          disabled={disablePrimaryActions || submittingType !== null}
          onClick={handleRejectMenuOpen}
          endIcon={<ExpandMore />}
        >
          Reject
        </LoadingButton>
        <RejectPrivatePolicyMenu
          isOpen={menuOpen}
          setRejectAnchorEl={setRejectAnchorEl}
          rejectAnchorEl={rejectAnchorEl}
          handleReject={handleReject}
        />
        <LoadingButton
          sx={{
            bgcolor: blue[500],
            color: "white",
            textTransform: "none",
            fontSize: "14px",
            "&:hover": {
              bgcolor: blue[600],
            },
            "&:disabled": {
              bgcolor: (theme: Theme) => theme.palette.grey[100],
              color: (theme: Theme) => theme.palette.grey[500],
            },
          }}
          onClick={handleApprove}
          disabled={disablePrimaryActions || !allFieldsSelected || !allFieldsValid || !allFieldSourcesValid || submittingType !== null}
          loading={submittingType === PrivatePolicyValidationStatus.accepted}
        >
          Accept
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
