import { useState } from "react";
import { FileUploadComponent } from "../../../components/FileUploadComponent/FileUploadComponent";
import { Button, Grid, Typography } from "@mui/material";
import { Todo } from "@safelease/service-utilities";
import { TodoReadAttachmentFileType, TodoType } from "@safelease/service-utilities/enums";
import dayjs from "dayjs";

const reportName = {
  [TodoType.ESS_TENANT_DATA_REPORT]: "Tenant Data Report",
  [TodoType.ESS_UNITS_LIST_REPORT]: "Units List Report",
  [TodoType.ESS_SELF_INSURED_RENTALS_REPORT]: "Self Insured Rentals Report",
  [TodoType.ESS_INSURANCE_REPORT]: "Insurance Report",
  [TodoType.ESS_INSURANCE_PLANS_SETTINGS]: "Insurance Plans Settings",
  [TodoType.ESS_SETUP_SETTINGS]: "Setup Settings",
  [TodoType.STOREDGE_RENT_ROLL]: "Rent Roll Report",
  [TodoType.STOREDGE_INSURED_TENANTS_ROLL]: "Insured Tenants Report",
  [TodoType.STOREDGE_INSURANCE_ACTIVITY]: "Insurance Activity Report",
  [TodoType.STOREDGE_LAST_MONTH_INSURANCE_ACTIVITY_REPORT]: "Last Month's Insurance Activity Report",
  [TodoType.STOREDGE_TENANT_CONTACT_LIST_REPORT]: "Tenant Contact List Report",
};

function IngestionDocumentUploader({
  onSubmit,
  todo,
  todoId,
}: {
  onSubmit: (file: File, todoId: number) => void;
  todo: Todo;
  todoId: number;
}) {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  // Handle for loading state
  const onSubmitFile = async (file: File) => {
    try {
      setLoading(true);
      await onSubmit(file, todoId);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const res = { type: todo.type };
  const todoReadAttachmentFileTypes = TodoReadAttachmentFileType[res.type as keyof typeof TodoReadAttachmentFileType];

  const getTodoDateRange = (todo: Todo) => {
    const firstDayOfLastMonth = dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
    const lastDayOfLastMonth = dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
    const firstDayOfCurrentMonth = dayjs().startOf("month").format("YYYY-MM-DD");
    const lastDayOfCurrentMonth = dayjs().endOf("month").format("YYYY-MM-DD");
    switch (todo.type) {
      case TodoType.STOREDGE_INSURANCE_ACTIVITY:
        return `Date range: ${firstDayOfCurrentMonth} - ${lastDayOfCurrentMonth}`;
      case TodoType.STOREDGE_LAST_MONTH_INSURANCE_ACTIVITY_REPORT:
        return `Date range: ${firstDayOfLastMonth} - ${lastDayOfLastMonth}`;
      default:
        return "No date range selection necessary";
    }
  };

  return (
    <Grid container spacing={1}>
      {/* report name */}
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="700">
          {`${reportName[todo.type as keyof typeof reportName]} (${todoReadAttachmentFileTypes})`}
        </Typography>
        <Typography variant="body2" fontWeight="400" color="text.secondary">
          {getTodoDateRange(todo)}
        </Typography>
      </Grid>

      {/* file uploader */}
      <Grid item xs={12}>
        <FileUploadComponent
          handleNewFiles={(files) => setUploadedFiles(files)}
          uploadedFiles={uploadedFiles}
          loading={loading}
          accept={
            todoReadAttachmentFileTypes === "csv"
              ? {
                  "text/csv": [".csv"],
                }
              : {
                  "text/html": [".html"],
                }
          }
          onDeleteFile={() => setUploadedFiles([])}
        />
      </Grid>

      {/* upload button */}
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        {/* only handling for single file uploads for now */}
        <Button
          disabled={uploadedFiles.length === 0}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onSubmitFile(uploadedFiles[0])}
        >
          Upload
        </Button>
      </Grid>
    </Grid>
  );
}

export default IngestionDocumentUploader;
