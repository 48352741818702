import { create } from "zustand";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SafeleaseApi } from "../../../utils/apiInstances/SafeleaseApiInstance";
import { TodoAction } from "@safelease/service-utilities/enums";
import { Todo, UpdateTodoDto } from "@safelease/service-utilities";

export interface SelectedTodo extends Todo {
  locationName: string;
  locationAddress: string;
}

interface ProtectionState {
  selectedTodo: SelectedTodo | null;
  setSelectedTodo: (todo: SelectedTodo | null) => void;

  notes: string;
  setNotes: (notes: string) => void;
}

export const useProtectionState = create<ProtectionState>()((set) => ({
  selectedTodo: null,
  setSelectedTodo: (todo) => set({ selectedTodo: todo }),

  notes: "",
  setNotes: (notes: string) => set({ notes }),
}));

export const useProtection = () => {
  const { selectedTodo, notes, setNotes, setSelectedTodo } = useProtectionState();

  const getTodosQuery = useQuery({
    queryKey: ["todos"],
    queryFn: async () => {
      const response = await SafeleaseApi.getLocationsWithPendingTodos({ action: TodoAction.WRITE });
      return response.data;
    },
  });

  const updateTodoMutation = useMutation({
    mutationFn: (updateTodoDto: UpdateTodoDto) => SafeleaseApi.updateTodo(updateTodoDto),
    onSuccess: () => {
      getTodosQuery.refetch();
    },
  });

  return {
    selectedTodo,
    notes,
    setSelectedTodo,
    setNotes,
    getTodosQuery,
    updateTodoMutation,
  };
};
