import { useState } from "react";
import { PropertyInsuranceApi } from "../../../utils/apiInstances/PropertyInsuranceApiInstance";

export const PcRiskAssessmentPage = () => {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await PropertyInsuranceApi.getApiInfo();
      if (!response.data) {
        throw new Error("Failed to fetch data");
      }
      setData(JSON.stringify(response.data, null, 2)); // Pretty print JSON
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>So... Punk... Ya Feelin' Risky?</h1>
      <button onClick={fetchData} disabled={loading}>
        {loading ? "Assessing Risk..." : "Assess Risk"}
      </button>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {data && <pre style={{ background: "#f4f4f4", padding: "10px" }}>{data}</pre>}
    </div>
  );
};
