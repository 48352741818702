import { GridRowParams } from "@mui/x-data-grid-premium";
import { SafeleaseApi } from "../../../utils/apiInstances/SafeleaseApiInstance";
import { useQuery } from "@tanstack/react-query";
import { TodoAction } from "@safelease/service-utilities/enums";
import { IngestionTodoTable } from "./IngestionTodoTable";
import { GetLocationsWithPendingTodosResponse } from "@safelease/service-utilities";
import { useState } from "react";
import { IngestionTodoDrawer } from "./IngestionTodoDrawer";
import { Grid, LinearProgress } from "@mui/material";

function IngestionTabContainer() {
  const [selectedTodoResponse, setSelectedTodoResponse] = useState<GetLocationsWithPendingTodosResponse | null>(null);

  const {
    data: todosByLocation,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["getLocationsWithPendingTodos"],
    queryFn: async () => {
      const response = await SafeleaseApi.getLocationsWithPendingTodos({ action: TodoAction.READ });
      return response.data;
    },
  });

  const onRowClick = (params: GridRowParams<GetLocationsWithPendingTodosResponse>) => {
    setSelectedTodoResponse(params.row);
  };

  const onCloseDrawer = () => {
    setSelectedTodoResponse(null);
  };

  return (
    <Grid container>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <IngestionTodoTable todoResponses={todosByLocation || []} onRowClick={onRowClick} />
      </Grid>
      {selectedTodoResponse && (
        <Grid item xs={12}>
          <IngestionTodoDrawer locationId={selectedTodoResponse.locationId} onClose={onCloseDrawer} refreshTodoList={refetch} />
        </Grid>
      )}
    </Grid>
  );
}

export { IngestionTabContainer };
