import { Box } from "@mui/material";
import {
  DescriptionOutlined,
  Grading,
  Troubleshoot,
  ManageAccountsOutlined,
  GroupsOutlined,
  UpdateOutlined,
  LocationOnRounded,
  SsidChart,
  ChecklistRounded,
  AssessmentOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { ActionCards } from "../../components/ActionCards/ActionCards.tsx";
import { useAuthStore } from "../../hooks/useAuthStore.ts";

export function Home() {
  const navigate = useNavigate();
  const userAttributes = useAuthStore((state) => state.userAttributes);

  const tools = [
    {
      label: "Claims",
      handleClick: () => navigate("/claims"),
      icon: <DescriptionOutlined />,
      hide: userAttributes?.isClaimsEnabled !== "true",
    },
    {
      label: "Compliance",
      handleClick: () => navigate("/compliance"),
      icon: <Grading />,
      hide: userAttributes?.isComplianceEnabled !== "true",
    },
    {
      label: "Job Error Center",
      handleClick: () => navigate("/error_center"),
      icon: <Troubleshoot />,
      hide: userAttributes?.isJobCenterEnabled !== "true",
    },
    {
      label: "Tenant User Management",
      handleClick: () => navigate("/tenant-user-management"),
      icon: <GroupsOutlined />,
      hide: userAttributes?.isTenantUserAdmin !== "true",
    },
    {
      label: "Internal User Management",
      handleClick: () => navigate("/internal-user-management"),
      icon: <ManageAccountsOutlined />,
      hide: userAttributes?.isUserSettingsAdmin !== "true",
    },
    {
      label: "Data Change Manager",
      handleClick: () => navigate("/change-manager"),
      icon: <UpdateOutlined />,
      hide: userAttributes?.isAdminReqsEnabled !== "true",
    },
    {
      label: "Prospects",
      handleClick: () => navigate("/prospects"),
      icon: <LocationOnRounded />,
      hide: userAttributes?.isProspectsEnabled !== "true",
    },
    {
      label: "Unit Breakdown Report",
      handleClick: () => navigate("/unit-breakdown"),
      icon: <SsidChart />,
      hide: userAttributes?.isReportingAdmin !== "true",
    },
    {
      label: "Todo List",
      handleClick: () => navigate("/todo-list"),
      icon: <ChecklistRounded />,
      hide: userAttributes?.isTodoListEnabled !== "true",
    },
    {
      label: "P&C Center",
      handleClick: () => navigate("/pc"),
      icon: <AssessmentOutlined />,
      hide: userAttributes?.isPcEnabled !== "true",
    },
  ];

  return (
    <>
      <Box sx={{ mb: 2, pb: 4 }}>
        <ActionCards tools={tools} />
      </Box>
    </>
  );
}
