import { GridRowParams } from "@mui/x-data-grid-premium";
import { SafeLeaseDataGrid } from "@safelease/components";
import { GetLocationsWithPendingTodosResponse } from "@safelease/service-utilities";
import { TableZeroState } from "../pages/TableZeroState";
import { todoColumns } from "../shared/columnDefs";

interface IngestionTodoTableProps {
  todoResponses: GetLocationsWithPendingTodosResponse[];
  onRowClick: (params: GridRowParams) => void;
}

function IngestionTodoTable({ todoResponses, onRowClick }: IngestionTodoTableProps) {
  return (
    <SafeLeaseDataGrid
      rows={todoResponses.map((todo) => ({
        id: todo.locationId,
        ...todo,
      }))}
      columns={todoColumns}
      pagination
      pageSizeOptions={[10, 25, 50]}
      onRowClick={onRowClick}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: 0,
          },
        },
      }}
      loading={false}
      sx={tableStyles}
      hideFooter={false}
      slots={{ noRowsOverlay: () => <TableZeroState message={"No data available."} /> }}
    />
  );
}

export { IngestionTodoTable };

export const tableStyles = {
  borderColor: "#EBEFF7",
  // Set the height and overflow for the DataGrid
  height: "100%", // Ensure it fills the Card height
  minHeight: "200px",
  overflow: "auto", // Enable scrolling
  "& .MuiDataGrid-cell": {
    borderColor: "#EBEFF7",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none", // Removes cell selection outline
  },
};
